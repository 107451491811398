//There's probably better way to do this, but this is working for now..

export const sidebarWidth = 360;
export const sideBarHeight = "calc(100vh - 132px)";

export const headerAndTabHeight = 122;

export const loadingSpinnerHeight = 380;

export const fullRankArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
