const env = process.env.NODE_ENV;

const configs = {
  production: {
    callback_uri: "https://www.mapper.bike",
    redirect_url: "https://www.mapper.bike",
  },
  dev: {
    callback_uri: "http://localhost:3000",
    redirect_url: "http://localhost:8080",
  },
  test: {
    callback_uri: "http://localhost:8080",
    redirect_url: "http://localhost:8080",
  },
};

const keys = configs[env] || configs.dev;

keys.client_id = "36974";
keys.client_secret = "53a58a6bc3f6a2954f9814ce66e2ab407eeec866";
keys.client_refresh = "14513595958be4f51d8bec701583598ba74e260c";
keys.secretSuperKey = "safw42346sDrPepperdrabbleRabble45";
keys.mapsApi = "AIzaSyBu3Q9252Tte1DWeAYe9GnwGHihNhuF1bo";
keys.subscriptionId = 203075;
keys.sbmtPassword = "BikesBeersBaby3a58a6bc3f6a";

const dbTableNames = {
  production: {
    activities: "activities",
    segmentEfforts: "segmentEfforts",
    segmentDetails: "segmentDetails",
    users: "users",
  },
  test: {
    activities: "activities-dev",
    segmentEfforts: "segmentEfforts-dev",
    segmentDetails: "segmentDetails-dev",
    users: "users-dev",
  },
  dev: {
    activities: "activities-dev",
    segmentEfforts: "segmentEfforts-dev",
    segmentDetails: "segmentDetails-dev",
    users: "users-dev",
  },
};

keys.dbTables = dbTableNames[env] || dbTableNames.dev;

module.exports = keys;

